import { ArtTier, DecorationType, DecorationStyle } from './base-types';

export function labelForDecorationTypeColor(t?: DecorationType): string {
  switch (t) {
    case DecorationType.HeatPress:
      return 'Heat Press Color';
    case DecorationType.Embroidery:
      return 'Thread Color';
    case DecorationType.Flocking:
      return 'Flock Color';
    case DecorationType.CustomTag:
      return 'Custom Tag Color';
    default:
      return 'Ink Color';
  }
}

export function labelForDecorationType(t?: DecorationType): string {
  switch (t) {
    case DecorationType.HeatPress:
      return 'Heat Press';
    case DecorationType.Embroidery:
      return 'Embroidery';
    case DecorationType.TackleTwill:
      return 'Tackle Twill';
    case DecorationType.Flocking:
      return 'Flocking';
    case DecorationType.CustomTag:
      return 'Custom Tag';
    case DecorationType.Promo:
      return 'Promo Product';
    case DecorationType.Lace:
      return 'Lace';
    case DecorationType.Bow:
      return 'Bow';
    case DecorationType.Stitching:
      return 'Stitching';
    default:
      return 'Screen Print';
  }
}

export function labelForArtTier(t: ArtTier): string {
  switch (t) {
    case ArtTier.NotSet:
      return 'Not Set';
    case ArtTier.Creative:
      return 'Creative';
    case ArtTier.Original:
      return 'Original';
    case ArtTier.Signature:
      return 'Signature';
    case ArtTier.EComm:
      return 'E-Comm';
    case ArtTier.NoDesignNeeded:
      return 'No Design Needed';
    case ArtTier.ProofOnly:
      return 'Proof Only';
  }
}

export function labelForDecorationStyle(t: DecorationStyle): string {
  switch (t) {
    case DecorationStyle.Normal:
      return 'Normal';
    case DecorationStyle.Vintage:
      return 'Vintage';
    case DecorationStyle.PuffPrint:
      return 'Puff Print';
    case DecorationStyle.SimulatedProcess:
      return 'Simulated Process';
    case DecorationStyle.OneHit:
      return 'One Hit';
    case DecorationStyle.VintageWithUnderbase:
      return 'Vintage with Underbase';
    case DecorationStyle.PhotoshopSeparation:
      return 'Photoshop Separation';
    case DecorationStyle.PuffEmbroidery:
      return 'Puff Embroidery';
  }
}
