import React, { useContext, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { startCase } from 'lodash';
import { extractErrorMessage } from '../fetch-local';
import Loader from './loader';
import Location from './ArtSubmissionApp/Location';
import LineItem from './ArtSubmissionApp/LineItem';
import { LoadState } from '../remote-data-types';
import { labelForArtTier } from '../labels';
import {
  ArtSubmissionContext,
  ArtSubmissionContextProvider,
  ArtJob,
  Location as L,
  LineItem as LI,
} from './ArtSubmissionApp/ArtSubmissionContext';
import useInterval from './useInterval';
import {
  get,
  saveArtJob,
  saveLineItem,
  saveLocation,
  updateArtTier,
  updateCollegeMarks,
  submitArt,
  purgeLocationProof,
  purgeLineItemComposite,
} from './ArtSubmissionApp/Store';
import { AttachmentUpdateType } from '../attachment-types';
import ErrorBox from './ErrorBox';
import NotificationBox from './NotificationBox';
import {
  Permissions,
  NotificationType,
  ArtTier,
  DecorationType,
} from '../base-types';
import PostsApp from './PostsApp';
import AutoSaveStatus from './AutoSaveStatus';
import IconSvg from './IconSvg';
import { Color } from './../BrandColor';
import Notification from './notification';

// Autosave interval
const AUTOSAVE_INTERVAL = 2 * 1000;

interface Props {
  links: {
    self: string;
    directUpload: string;
  };
  artJobCurrentState: string;
}

const DEFAULT_SERVER_ERROR = ['An unknown error occured, please try again'];

const anythingToUpdate = (perms: Permissions[]): boolean =>
  perms.reduce((accum, perm) => accum || perm === Permissions.ReadWrite, false);

const canUpdateArtJob = ({ permissions }: ArtJob): boolean =>
  anythingToUpdate([
    permissions.artTier,
    permissions.collegiate,
    permissions.greek,
    permissions.printColorChange,
  ]);

const canUpdateLocation = ({ permissions }: L): boolean =>
  anythingToUpdate([
    permissions.proof,
    permissions.decorationStyle,
    permissions.productionNotes,
    permissions.threadCount,
    permissions.colors,
  ]);

const canUpdateLineItem = ({ permissions }: LI): boolean =>
  anythingToUpdate([permissions.images]);

function App(props: Props) {
  const { state, dispatch } = useContext(ArtSubmissionContext);

  const {
    links,
    artJob,
    order,
    locations,
    lineItems,
    loadStatus,
    isSubmitting,
  } = state;

  const save = useCallback(
    (forceSave: boolean = false) => {
      // TODO: do not continue if currently saving other updates

      if (
        state.artJob &&
        (state.artJob.dirty || forceSave) &&
        canUpdateArtJob(state.artJob)
      ) {
        console.log('Autosaving art job...');

        dispatch({
          type: 'update-art-job-save-status',
          payload: LoadState.IndeterminateProgressLoading,
        });

        saveArtJob(state.artJob)
          .then(() => {
            dispatch({ type: 'update-errors', payload: [] });

            dispatch({
              type: 'update-art-job-save-status',
              payload: LoadState.Success,
            });
          })
          .catch(async e => {
            const messages = await extractErrorMessage(e, DEFAULT_SERVER_ERROR);
            dispatch({ type: 'update-errors', payload: messages });
            dispatch({
              type: 'update-art-job-save-status',
              payload: LoadState.Failure,
            });
          });
        dispatch({ type: 'update-dirty', payload: false });
      }

      state.locations.forEach(location => {
        if (!(location.dirty || forceSave) || !canUpdateLocation(location))
          return;
        console.log('Autosaving location...');

        dispatch({
          type: 'update-location-save-status',
          id: location.id,
          payload: LoadState.IndeterminateProgressLoading,
        });

        saveLocation(location)
          .then(l => {
            if (
              location.proofUpdate.type ===
              AttachmentUpdateType.RemoveAttachment
            ) {
              purgeLocationProof(location).then(l => {
                dispatch({
                  type: 'update-location-proof',
                  id: location.id,
                  payload: {
                    attachment: l.proof,
                    status: l.proofStatus,
                  },
                });
              });
            }

            dispatch({
              type: 'update-location-proof',
              id: l.id,
              payload: {
                attachment: l.proof,
                status: l.proofStatus,
              },
            });

            dispatch({
              type: 'update-location-errors',
              id: location.id,
              payload: [],
            });

            dispatch({
              type: 'update-location-save-status',
              id: location.id,
              payload: LoadState.Success,
            });
          })
          .catch(async e => {
            const messages = await extractErrorMessage(e, DEFAULT_SERVER_ERROR);
            dispatch({
              type: 'update-location-errors',
              id: location.id,
              payload: messages,
            });

            dispatch({
              type: 'update-location-save-status',
              id: location.id,
              payload: LoadState.Failure,
            });
          });

        dispatch({
          type: 'update-location-dirty',
          id: location.id,
          payload: false,
        });
      });

      state.lineItems.forEach(lineItem => {
        if (!(lineItem.dirty || forceSave) || !canUpdateLineItem(lineItem))
          return;

        dispatch({
          type: 'update-line-item-save-status',
          id: lineItem.id,
          payload: LoadState.IndeterminateProgressLoading,
        });

        console.log('Autosaving line item...');

        saveLineItem(lineItem)
          .then(l => {
            dispatch({
              type: 'update-line-item-errors',
              id: lineItem.id,
              payload: [],
            });

            if (
              lineItem.primaryImageUpdate.type ===
              AttachmentUpdateType.RemoveAttachment
            ) {
              purgeLineItemComposite(lineItem, 'primary')
                .then(l => {
                  dispatch({
                    type: 'update-line-item-primary-image',
                    id: lineItem.id,
                    payload: {
                      attachment: l.compositePrimary,
                      status: l.compositePrimaryStatus,
                    },
                  });
                })
                .catch(async e => {
                  const messages = await extractErrorMessage(
                    e,
                    DEFAULT_SERVER_ERROR
                  );
                  dispatch({
                    type: 'update-line-item-errors',
                    id: lineItem.id,
                    payload: messages,
                  });
                });
            }
            if (
              lineItem.secondaryImageUpdate.type ===
              AttachmentUpdateType.RemoveAttachment
            ) {
              purgeLineItemComposite(lineItem, 'secondary')
                .then(l => {
                  dispatch({
                    type: 'update-line-item-secondary-image',
                    id: lineItem.id,
                    payload: {
                      attachment: l.compositeSecondary,
                      status: l.compositeSecondaryStatus,
                    },
                  });
                })
                .catch(async e => {
                  const messages = await extractErrorMessage(
                    e,
                    DEFAULT_SERVER_ERROR
                  );
                  dispatch({
                    type: 'update-line-item-errors',
                    id: lineItem.id,
                    payload: messages,
                  });
                });
            }

            if (
              l &&
              lineItem.primaryImageUpdate.type ===
                AttachmentUpdateType.AddAttachment
            ) {
              dispatch({
                type: 'update-line-item-primary-image',
                id: l.id,
                payload: {
                  attachment: l.compositePrimary,
                  status: l.compositePrimaryStatus,
                },
              });
            }

            if (
              l &&
              lineItem.secondaryImageUpdate.type ===
                AttachmentUpdateType.AddAttachment
            ) {
              dispatch({
                type: 'update-line-item-secondary-image',
                id: l.id,
                payload: {
                  attachment: l.compositeSecondary,
                  status: l.compositeSecondaryStatus,
                },
              });
            }

            dispatch({
              type: 'update-line-item-save-status',
              id: lineItem.id,
              payload: LoadState.Success,
            });
          })
          .catch(async e => {
            const messages = await extractErrorMessage(e, DEFAULT_SERVER_ERROR);
            dispatch({
              type: 'update-line-item-errors',
              id: lineItem.id,
              payload: messages,
            });
            dispatch({
              type: 'update-line-item-save-status',
              id: lineItem.id,
              payload: LoadState.Failure,
            });
          });

        dispatch({
          type: 'update-line-item-dirty',
          id: lineItem.id,
          payload: false,
        });
      });
    },
    [state, dispatch]
  );

  useEffect(() => {
    dispatch({ type: 'loading-began' });

    async function loadData() {
      try {
        const response = await get(props.links.self);
        dispatch({ type: 'loading-succeeded', payload: response });
      } catch (error) {
        dispatch({ type: 'loading-failed', payload: error });
      }
    }

    loadData();
  }, [dispatch, props.links.self]);

  useInterval(save, AUTOSAVE_INTERVAL);

  const onArtTierChange = e => {
    if (!artJob) return;
    const artTier: ArtTier = Number.parseInt(e.target.value);

    dispatch({
      type: 'update-art-job-direct-save-status',
      payload: { state: LoadState.IndeterminateProgressLoading },
    });

    updateArtTier(artJob, artTier)
      .then(() => {
        dispatch({
          type: 'update-art-job-direct-save-status',
          payload: { state: LoadState.Success, data: undefined },
        });
      })
      .catch(async e => {
        const messages = await extractErrorMessage(e, DEFAULT_SERVER_ERROR);
        dispatch({
          type: 'update-art-job-direct-save-status',
          payload: { state: LoadState.Failure, error: messages },
        });
      });

    dispatch({
      type: 'update-art-tier',
      payload: artTier,
    });
  };

  const onCollegeMarksChange = e => {
    if (!artJob) return;
    const collegeMarks: boolean = e.target.checked;

    dispatch({
      type: 'update-art-job-direct-save-status',
      payload: { state: LoadState.IndeterminateProgressLoading },
    });

    updateCollegeMarks(artJob, collegeMarks)
      .then(() => {
        dispatch({
          type: 'update-art-job-direct-save-status',
          payload: { state: LoadState.Success, data: undefined },
        });
      })
      .catch(async e => {
        const messages = await extractErrorMessage(e, DEFAULT_SERVER_ERROR);
        dispatch({
          type: 'update-art-job-direct-save-status',
          payload: { state: LoadState.Failure, error: messages },
        });
      });

    dispatch({
      type: 'update-college-marks',
      payload: collegeMarks,
    });
  };

  const onSubmitArt = e => {
    if (!artJob || isSubmitting) return;
    dispatch({ type: 'submitting-began' });
    submitArt(artJob)
      .then(() => {
        const messages = ['Successfully submitted art'];
        const type = NotificationType.Success;
        dispatch({
          type: 'update-notifications',
          payload: {
            messages,
            type,
          },
        });
        if (links) window.location.href = links.order;
      })
      .catch(async e => {
        const messages = await extractErrorMessage(e, 'wat');
        const type = NotificationType.Alert;
        dispatch({
          type: 'update-notifications',
          payload: {
            messages,
            type,
          },
        });
        dispatch({ type: 'submitting-finished' });
      });
  };

  const canSubmitArtJob = artJob && artJob.permissions.canSubmitArtwork;

  const missingRevisions = state.locations.reduce(
    (result, location) =>
      result ||
      location.revisions
        .map(r => !r.completed)
        .reduce((res, r) => res || r, false),
    false
  );

  const missingArt =
    state.locations.reduce(
      (result, location) =>
        result ||
        location.proof === null ||
        location.proofStatus === AttachmentUpdateType.RemoveAttachment,
      false
    ) ||
    state.lineItems.reduce(
      (result, lineItem) =>
        result ||
        lineItem.compositePrimary === null ||
        lineItem.compositePrimaryStatus ===
          AttachmentUpdateType.RemoveAttachment,
      false
    );

  const missingColors = state.locations.reduce(
    (result, location) =>
      result ||
      (!['TackleTwill', 'Flocking', 'Bow', 'Lace', 'Stitching']
        .map(key => DecorationType[key])
        .includes(location.decorationType) &&
        location.colors.length === 0),
    false
  );

  const missingLocation = state.locations.length === 0;
  const missingLineItem = state.lineItems.length === 0;

  const isLoading =
    (state.artJob &&
      (state.artJob.saveStatus === LoadState.IndeterminateProgressLoading ||
        state.artJob.saveStatus === LoadState.Loading ||
        state.artJob.dirty)) ||
    state.locations.reduce(
      (result, location) =>
        result ||
        location.saveStatus === LoadState.IndeterminateProgressLoading ||
        location.saveStatus === LoadState.Loading ||
        location.dirty,
      false
    ) ||
    state.lineItems.reduce(
      (result, lineItem) =>
        result ||
        lineItem.saveStatus === LoadState.IndeterminateProgressLoading ||
        lineItem.saveStatus === LoadState.Loading ||
        lineItem.dirty,
      false
    );

  let itemsPreventingSubmission: string[] = [];
  if (missingRevisions) {
    itemsPreventingSubmission = [
      ...itemsPreventingSubmission,
      'You must complete all revisions before submitting art.',
    ];
  }

  if (missingLocation) {
    itemsPreventingSubmission = [
      ...itemsPreventingSubmission,
      'Your order must have at least one location to submit art.',
    ];
  }

  if (missingLineItem) {
    itemsPreventingSubmission = [
      ...itemsPreventingSubmission,
      'Your order must have at least one item to submit art.',
    ];
  }

  if (missingArt) {
    itemsPreventingSubmission = [
      ...itemsPreventingSubmission,
      'You must submit art for each location and line item before submitting art.',
    ];
  }

  if (missingColors) {
    itemsPreventingSubmission = [
      ...itemsPreventingSubmission,
      'You must add colors for each location before submitting art.',
    ];
  }

  if (isLoading) {
    itemsPreventingSubmission = [
      ...itemsPreventingSubmission,
      'Please wait for items below to finish saving.',
    ];
  }

  const maySubmitArt = itemsPreventingSubmission.length === 0;

  const supportOnlyMaySubmitArt = props.artJobCurrentState === 'shipped';

  return (
    <>
      {state.notifications && <NotificationBox {...state.notifications} />}

      <Loader isActive={loadStatus === LoadState.Loading} />

      {loadStatus === LoadState.Success && artJob && locations && order && (
        <>
          <div className="fixed-header flex-rows flex-rows--center-v flex-rows--collapse flex-rows--space-b">
            <h1 className="mvn">
              {links ? (
                <a href={links.order} className="headline-link">
                  {order.jobNumber}
                </a>
              ) : (
                order.jobNumber
              )}
              <span className="headline-supplement">{order.name}</span>
            </h1>
            <div className="flex-rows flex-rows--center-v">
              {canSubmitArtJob && !maySubmitArt ? (
                <div data-tooltip={itemsPreventingSubmission.join('\n')}>
                  <IconSvg icon="warning" color={Color.Yellow} size={32} />
                </div>
              ) : null}
              {isSubmitting && (
                <div data-tooltip="Your art is currently submitting. Please wait.">
                  <Loader />
                </div>
              )}
              {canSubmitArtJob && (
                <button
                  className={`button button--full-res${
                    maySubmitArt !== true ? ` mlm` : ``
                  }${
                    supportOnlyMaySubmitArt === true ? ' button--support' : ''
                  }`}
                  disabled={maySubmitArt !== true || isSubmitting}
                  onClick={onSubmitArt}
                  title={
                    maySubmitArt === true
                      ? undefined
                      : itemsPreventingSubmission.join('\n')
                  }
                >
                  Submit Artwork
                </button>
              )}
            </div>
          </div>
          <div className="mbl">
            <span
              className={classNames({
                label: true,
                'label--rounded': true,
                [artJob.currentState.class]: true,
              })}
            >
              {startCase(artJob.currentState.state)}
            </span>

            {artJob.uteesOriginalArt && artJob.artCode && (
              <span
                className={classNames({
                  label: true,
                  'label--rounded': true,
                  mlm: true,
                })}
              >
                Art Code: {artJob.artCode}
              </span>
            )}
          </div>

          <div className="l-section--bordered pan">
            <AutoSaveStatus saveStatus={artJob.saveStatus} />

            {artJob.errors.length > 0 && (
              <div className="row">
                <div className="col-6">
                  <ErrorBox errors={artJob.errors} />
                </div>
              </div>
            )}

            <div className="location__section location__section--2">
              <div className="location__component stack">
                <div className="form-light form-light--bordered">
                  {artJob.directSaveStatus.state ===
                    LoadState.IndeterminateProgressLoading && <Loader />}
                  {artJob.directSaveStatus.state === LoadState.Success && (
                    <Notification small type="success" autoDismiss>
                      Saved!
                    </Notification>
                  )}
                  {artJob.directSaveStatus.state === LoadState.Failure && (
                    <Notification small type="warning" autoDismiss>
                      {artJob.directSaveStatus.error}
                    </Notification>
                  )}

                  <div className="label-select mbm">
                    <label htmlFor="art-tier" className="better-label">
                      Art Tier
                    </label>
                    {artJob.permissions.artTier === Permissions.ReadOnly ? (
                      <div>{labelForArtTier(artJob.artTier)}</div>
                    ) : (
                      <select
                        id="art-tier"
                        value={artJob.artTier}
                        onChange={onArtTierChange}
                        onBlur={() => {}}
                      >
                        <option value={0}>Not Set</option>
                        <option value={1}>Original</option>
                        <option value={2}>Signature</option>
                        <option value={3}>Creative</option>
                        <option value={4}>EComm</option>
                        <option value={5}>No Design Needed</option>
                        <option value={6}>Proof Only</option>
                      </select>
                    )}
                  </div>

                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="college-marks"
                      checked={artJob.collegeMarks}
                      onChange={onCollegeMarksChange}
                      disabled={
                        artJob.permissions.collegeMarks === Permissions.ReadOnly
                      }
                    />
                    <label htmlFor="college-marks">College Marks?</label>
                  </div>

                  <hr className="mvm" />

                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="greek"
                      checked={artJob.greek}
                      onChange={e =>
                        dispatch({
                          type: 'update-greek',
                          payload: e.target.checked,
                        })
                      }
                      disabled={
                        artJob.permissions.greek === Permissions.ReadOnly
                      }
                    />
                    <label htmlFor="greek">Greek Royalties?</label>
                  </div>
                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="collegiate"
                      checked={artJob.collegiate}
                      onChange={e =>
                        dispatch({
                          type: 'update-collegiate',
                          payload: e.target.checked,
                        })
                      }
                      disabled={
                        artJob.permissions.collegiate === Permissions.ReadOnly
                      }
                    />
                    <label htmlFor="collegiate">Collegiate Royalties?</label>
                  </div>
                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="original-art"
                      checked={artJob.uteesOriginalArt}
                      onChange={e =>
                        dispatch({
                          type: 'update-utees-original-art',
                          payload: e.target.checked,
                        })
                      }
                      disabled={
                        artJob.permissions.uteesOriginalArt ===
                        Permissions.ReadOnly
                      }
                    />
                    <label htmlFor="original-art">UTees Original Art?</label>
                  </div>
                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="print-color-changes"
                      checked={artJob.printColorChange}
                      onChange={e =>
                        dispatch({
                          type: 'update-print-color-change',
                          payload: e.target.checked,
                        })
                      }
                      disabled={
                        artJob.permissions.printColorChange ===
                        Permissions.ReadOnly
                      }
                    />
                    <label htmlFor="print-color-changes">
                      Print color changes?
                    </label>
                  </div>
                </div>

                {(artJob.school || artJob.organization) && (
                  <div>
                    <h3 className="better-label">School &amp; Organization</h3>
                    <ul className="list-plain">
                      {artJob.school && (
                        <li>
                          <span className="txt-muted">
                            <i className="fa fa-graduation-cap fa-fw" />
                          </span>{' '}
                          {artJob.school.name}
                        </li>
                      )}
                      {artJob.organization && (
                        <li>
                          <span className="txt-muted">
                            <i className="fa fa-university fa-fw" />
                          </span>{' '}
                          {artJob.organization.name}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="location__component bg-light-blue">
                <div className="form-light form-light--bordered">
                  <label htmlFor="allocator-notes" className="better-label">
                    Allocator Notes
                  </label>

                  <PostsApp
                    postableType="ArtJob"
                    postableId={artJob.id}
                    postableName="allocatorNotes"
                    collectionLabel="allocator notes"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-rows flex-rows--bottom-v flex-rows--space-b flex-rows--collapse mtl mbm">
            <h2 className="mvn">
              Locations
              <span className="headline-supplement">{locations.length}</span>
            </h2>
            {links && artJob.permissions.canAccessLocations && (
              <div>
                <a
                  href={links.locations}
                  className="button button--small button--secondary"
                  type="submit"
                >
                  Edit Locations
                </a>
              </div>
            )}
          </div>

          <div className="stack">
            {locations.map(location => (
              <Location
                directUploadUrl={props.links.directUpload}
                key={location.id}
                location={location}
              />
            ))}
          </div>

          <h2 className="mbm">
            Items
            <span className="headline-supplement">{lineItems.length}</span>
          </h2>

          <div className="stack stack--large">
            {lineItems.map(lineItem => (
              <LineItem
                key={lineItem.id}
                lineItem={lineItem}
                directUploadUrl={props.links.directUpload}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default function ArtSubmissionApp(props: Props) {
  return (
    <ArtSubmissionContextProvider>
      <App {...props} />
    </ArtSubmissionContextProvider>
  );
}
