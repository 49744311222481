// @ts-check

import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import CheckBoxField from '../check-box-field';

/**
 *
 * @param {Object} props
 * @param {Object} props.item
 * @param {Function} props.onSubmit
 * @param {Object[]} props.products
 * @param {string} props.submitButtonText
 * @param {boolean} props.isExistingProduct
 * @param {boolean} props.hideCommissioningData
 * @param {boolean} props.fullAccess
 */
export default function ItemForm({
  item = {},
  products,
  submitButtonText,
  ...props
}) {
  const [productId, setProductId] = useState(item.productId);
  const [colorsProductId, setColorsProductId] = useState(item.colorsProductId);
  const [quantity, setQuantity] = useState(item.quantity);
  const [isIndividualPayment, setIsIndividualPayment] = useState(
    item.isIndividualPayment
  );
  const [isBagAndTag, setIsBagAndTag] = useState(item.isBagAndTag);
  const [isIndividualShip, setIsIndividualShip] = useState(
    item.isIndividualShip
  );
  const [isHeatTagging, setIsHeatTagging] = useState(item.isHeatTagging);
  const [isCustom, setIsCustom] = useState(item.isCustom);
  const [customItemAttributes, setCustomItemAttributes] = useState({});

  const selectedProduct = useMemo(
    () => products.find(product => product.productId === productId),
    [productId, products]
  );
  const handleSubmit = e => {
    e.preventDefault();

    props.onSubmit({
      item,
      ...(isCustom ? customItemAttributes : { productId, colorsProductId }),
      isBagAndTag,
      isIndividualPayment,
      isIndividualShip,
      isHeatTagging,
      quantity,
    });
  };

  /**
   *
   * @param {Object} newAttributes
   * @returns {void}
   */
  const handleCustomItemAttributesChange = newAttributes => {
    setCustomItemAttributes({ ...customItemAttributes, ...newAttributes });
  };

  return (
    <form onSubmit={handleSubmit} className="form-vertical">
      <h3 className="mbs">Item Details</h3>
      {/* @ts-ignore  */}
      <CheckBoxField
        id="custom"
        label="Custom?"
        checked={isCustom}
        onChange={({ target: { checked } }) => setIsCustom(checked)}
      />

      <div className="row">
        {isCustom && (
          /** @ts-ignore */
          <CustomItemFields
            {...customItemAttributes}
            onUpdate={handleCustomItemAttributesChange}
          />
        )}
        {!isCustom && (
          <div>
            <div className="col-8">
              <label htmlFor="product-select">Product</label>
              <Select
                id="product-select"
                value={productId}
                placeholder="Product"
                onChange={option => {
                  setProductId(option ? option['value'] : null);
                  setColorsProductId(null);
                }}
                options={products.map(({ name, id }) => ({
                  value: id,
                  label: name,
                }))}
              />
            </div>
            <div className="col-2">
              <label htmlFor="color-select">Color</label>
              <Select
                id="color-select"
                value={colorsProductId}
                placeholder="Color"
                onChange={option =>
                  setColorsProductId(option ? option['value'] : null)
                }
                options={((selectedProduct && selectedProduct['colors']) || [])
                  .sort(({ name: nameA }, { name: nameB }) => nameA > nameB)
                  .map(({ name, colorsProductId }) => ({
                    value: colorsProductId,
                    label: name,
                  }))}
              />
            </div>
          </div>
        )}
        <div className="col-2">
          <div>
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              id="quantity"
              step="1"
              min="1"
              required={true}
              value={quantity || ''}
              placeholder="0"
              onChange={({ target: { value: newQuantity } }) =>
                setQuantity(parseInt(newQuantity))
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          {/* @ts-ignore  */}
          <CheckBoxField
            id="ind-pay"
            label="Ind Pay Bird Bank?"
            checked={isIndividualPayment}
            onChange={({ target: { checked } }) =>
              setIsIndividualPayment(checked)
            }
          />
        </div>

        <div className="col-3">
          {/* @ts-ignore  */}
          <CheckBoxField
            id="bag-tag"
            label="Bag and Tag?"
            checked={isBagAndTag}
            onChange={({ target: { checked } }) => setIsBagAndTag(checked)}
          />
        </div>

        <div className="col-3">
          {/* @ts-ignore  */}
          <CheckBoxField
            id="ind-ship"
            label="Individual ship?"
            checked={isIndividualShip}
            onChange={({ target: { checked } }) => setIsIndividualShip(checked)}
          />
        </div>

        <div className="col-3">
          {/* @ts-ignore  */}
          <CheckBoxField
            id="heat-tag"
            label="Brand tagging?"
            checked={isHeatTagging}
            onChange={({ target: { checked } }) => setIsHeatTagging(checked)}
          />
        </div>
      </div>
      <div className="row">
        <div className="push-3 col-9">
          <button className="button button--large l-right">
            {submitButtonText || 'Add Item'}
          </button>
        </div>
      </div>
    </form>
  );
}

/**
 * @param {Object} props
 * @param {String} props.description
 * @param {Number | Null} props.price
 * @param {String} props.shippingType
 * @param {Function} props.onUpdate
 * @returns {JSX.Element}
 */
function CustomItemFields({
  description = '',
  price = null,
  shippingType = '',
  onUpdate = (/** @type {Object} */ _) => {},
}) {
  return (
    <div>
      <div className="col-3">
        <div>
          <label htmlFor="description">Item Description</label>
          <input
            type="text"
            id="description"
            required={true}
            value={description}
            placeholder="Description"
            onChange={({ target: { value } }) => {
              onUpdate({ description: value });
            }}
          />
        </div>
      </div>
      <div className="col-2">
        <div>
          <label htmlFor="price" className="show">
            Base Price
          </label>
          <input
            type="number"
            id="price"
            step="any"
            min="0"
            value={price || ''}
            placeholder="Base Price"
            required={true}
            onChange={({ target: { value } }) =>
              onUpdate({ price: parseFloat(value) })
            }
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="shipping-type" className="label-select">
          Shipping Type
          <select
            id="shipping-type"
            value={shippingType}
            onChange={({ target: { value } }) =>
              onUpdate({ shippingType: value })
            }
            required={true}
            style={{ backgroundColor: '#F3F3F3' }}
          >
            <option value="">Shipping Type</option>
            <option value="shipping_light">Light</option>
            <option value="shipping_medium">Medium</option>
            <option value="shipping_heavy">Heavy</option>
            <option value="shipping_super_heavy">Super Heavy</option>
          </select>
        </label>
      </div>
    </div>
  );
}
